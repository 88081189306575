<template>
    <div class="gst-ticket-offer-card-variant-1 d-flex flex-row align-center u-width-100 pl-2 pr-4">
        <div class="flex-grow-1">
            <TicketOfferTypeIconVariant1
                :type="ticket.selection"
                :offer-name="item.offer.name"
                class="gst-ticket-offer-card-variant-1__item flex-grow-1" />
            <div class="gst-ticket-offer-card-variant-1__item-price d-flex align-center">
                {{ price | currencyFilter( offer.currency ) }} / {{ $t( '_common:terms.eachSeat_abv' ) }}
            </div>
            <TicketPriceBreakdown
                v-if="offer"
                class="gst-ticket-offer-card-variant-1__item-price-breakdown d-flex"
                :base-price="offer.basePrice"
                :fee-price="offer.feePrice"
                :currency="offer.currency"
                :all-inclusive-pricing="offer.allInclusivePricing" />
        </div>
        <div class="flex-shrink">
            <NumericStep
                v-if="isMultipleOffers"
                v-model="item.quantity"
                :min="0"
                :step="step"
                :max="maxQuantity"
                :values="offer && offer.ticketType && offer.ticketType.sellableQuantities ? offer.ticketType.sellableQuantities : [ ]"
                class="gst-ticket-offer-card-variant-1__input" />
            <div v-else
                class="gst-ticket-offer-card-variant-1__quantity">
                {{ item.quantity }}
            </div>
        </div>
    </div>
</template>

<script>
    import NumericStep from '@core/shared/components/input/NumericStep';
    import TicketOfferTypeIconVariant1 from '@tenant/app/components/icons/TicketOfferTypeIconVariant1';
    import TicketPriceBreakdown from '@tenant/app/components/cards/TicketPriceBreakdown.vue';

    export default {
        name: 'TicketOfferCardVariant1',
        components: {
            TicketOfferTypeIconVariant1,
            NumericStep,
            TicketPriceBreakdown
        },
        props: {
            item: {
                type: Object,
                required: true
            },
            ticket: {
                type: Object,
                required: true
            },
            maxQuantity: {
                type: Number,
                required: true
            },
            isMultipleOffers: {
                type: Boolean,
                default: false
            },
            step: {
                type: Number,
                default: 1
            }
        },
        computed: {
            offer( ) {
                return this.item.offer;
            },
            price( ) {
                const { offer } = this;

                return offer.allInclusivePricing ? offer.totalPrice : offer.basePrice;
            }
        },
        watch: {
            'item.quantity': function ( newValue ) {
                this.item.selected = !!newValue;
            }
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-ticket-offer-card-variant-1 {
        .gst-ticket-offer-card-variant-1__item {
            line-height: line-height( 'xl' );
            color: theme-color( 'primary' );
            font-size: font-size( 's' );
            text-transform: none;

            svg {
                height: 22px;
                width: 22px;
                margin-right: theme-spacing( 2 ) !important;
            }
        }

        .gst-ticket-offer-card-variant-1__item-price {
            line-height: line-height( 'xl' );
            padding-left: 30px;
            color: theme-color( 'tertiary' );
            font-size: font-size( 's' );
        }

        .gst-ticket-offer-card-variant-1__item-price-breakdown {
            padding-left: 30px;
            color: theme-color( 'tertiary' );
            font-size: font-size( 'xxs' );
        }

        .gst-ticket-offer-card-variant-1__quantity {
            line-height: line-height( 'xxxxxxl' );
            border: 0;
            background: theme-color( 'white' );
            color: theme-color( 'quaternary' );
            font-size: 26px;
            font-weight: font-weight( 'regular' );
            text-align: center;
        }

        .gst-ticket-offer-card-variant-1__input {
            height: 32px;

            .gst-numeric-step-input__content {
                line-height: line-height( 'xxxxxxl' );
                border: 0;
                background: theme-color( 'white' );
                color: theme-color( 'quaternary' );
                font-size: 26px;
                font-weight: font-weight( 'regular' );
                text-align: center;
                min-width: 48px;
            }

            .gst-numeric-step-input__minus-btn,
            .gst-numeric-step-input__plus-btn {
                height: 32px !important;
                width: 32px !important;
                border: 1px solid theme-color( 'primary' );
                background: theme-color( 'primary' );
                min-width: 32px !important;
                border-radius: 100%;

                svg {
                    .gst-svg-icon {
                        fill: theme-color( 'white' );
                    }
                }
            }

            .gst-numeric-step-input__minus-btn--disabled,
            .gst-numeric-step-input__plus-btn--disabled {
                border: 1px solid theme-color-hex( 'primary-lighten-3' );
                background: theme-color-hex( 'primary-lighten-3' );

                svg {
                    opacity: 0.4;
                }
            }
        }
    }
</style>
