<template>
    <div class="gst-ticket-detail-sidebar-multiple-offers-container">
        <div
            v-for="item in items"
            :key="item.offer.id"
            class="d-flex flex-row align-center u-width-100 mt-4">
            <TicketOfferCardVariant1
                :item="item"
                :ticket="ticket"
                :max-quantity="getMaxQuantityForOfferItem( item )"
                :step="step"
                :is-multiple-offers="true" />
        </div>
    </div>
</template>

<script>
    import { isResale as ticketUtilsIsResale } from '@core/utils/ticketUtils';
    import TicketOfferCardVariant1 from '@tenant/app/components/cards/TicketOfferCardVariant1';

    export default {
        name:  'EventTicketDetailSidebarMultipleOfferContainer',
        components: {
            TicketOfferCardVariant1
        },
        props: {
            items: {
                type: Array,
                required: true,
            },
            ticket: {
                type: Object,
                required: true,
            },
            quantity: {
                type: Number,
                required: true
            },
        },
        computed: {
            step( ) {
                if ( ticketUtilsIsResale( this.ticket.selection ) ) {
                    return this.quantity;
                }
                return 1;
            }
        },
        methods: {
            getMaxQuantityForOfferItem( item ) {
                return this.items.reduce( ( acc, { id, quantity } ) => {
                    return item.id === id ? acc : acc - quantity;
                }, this.quantity );
            }
        }
    };
</script>
