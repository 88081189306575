<template>
    <div class="gst-event-ticket-detail-sidebar u-height-100 d-flex flex-column">
        <EventTicketDetailSidebarHeader :ticket="ticket" :quantity="quantity" @close="$emit( 'close-ticket-details' )" />
        <EventTicketDetailSidebarTicketImage :ticket="ticket" />
        <div class="text-center overflow-auto u-height-100">
            <EventTicketDetailSidebarSingleOfferContainer v-if="!isMultipleOffers"
                :item="offerItems[0]"
                :ticket="ticket" />
            <EventTicketDetailSidebarMultipleOfferContainer v-else
                class="pb-4"
                :items="offerItems"
                :ticket="ticket"
                :quantity="quantity" />
        </div>
    </div>
</template>

<script>
    import EventTicketDetailSidebarHeader from './EventTicketDetailSidebar/EventTicketDetailSidebarHeader';
    import EventTicketDetailSidebarTicketImage from './EventTicketDetailSidebar/EventTicketDetailSidebarTicketImage';
    import EventTicketDetailSidebarSingleOfferContainer from './EventTicketDetailSidebar/EventTicketDetailSidebarSingleOfferContainer';
    import EventTicketDetailSidebarMultipleOfferContainer from './EventTicketDetailSidebar/EventTicketDetailSidebarMultipleOfferContainer';

    export default {
        name: 'EventTicketDetailSidebar',
        components: {
            EventTicketDetailSidebarHeader,
            EventTicketDetailSidebarTicketImage,
            EventTicketDetailSidebarSingleOfferContainer,
            EventTicketDetailSidebarMultipleOfferContainer,
        },
        props: {
            ticket: {
                type: Object,
                required: true,
            },
            offers: {
                type: Array,
                default: null
            },
            event: {
                type: Object,
                required: true
            },
            quantity: {
                type: Number,
                required: true
            },
            busEventParent: {
                type: Object,
                required: true
            },
            selectedOffers: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                offerItems: [],
            };
        },
        computed: {
            isMultipleOffers( ) {
                return this.offers.length > 1;
            },
        },
        watch: {
            'offerItems': {
                handler: function( value ) {
                    this.$emit( 'update-selected-offers', value.filter( item => item.quantity > 0 ) );
                },
                deep: true
            },
        },
        created( ) {
            this.offers.forEach( ( item ) => {
                const selectedOfferItem = this.selectedOffers.length && this.selectedOffers.find( selectedItem => item.id === selectedItem.id );
                const selected = selectedOfferItem ? selectedOfferItem.selected : false;
                const quantity = selectedOfferItem ? selectedOfferItem.quantity : 0;

                this.offerItems.push( {
                    id: item.id,
                    selected: !this.isMultipleOffers ? true : selected,
                    offer: item,
                    quantity: !this.isMultipleOffers ? this.quantity : quantity
                } );
            } );
            this.offers[ 0 ].quantity = this.quantity;
        }
    };
</script>

<style lang="scss">
    @import '@scssVariables';
    @import '@scssMixins';

    .gst-event-ticket-detail-sidebar {
        background: theme-color( 'white' );
    }
</style>
