<template>
    <div class="gst-ticket-detail-sidebar-single-offer-container pt-4">
        <TicketOfferCardVariant1
            :item="item"
            :ticket="ticket"
            :max-quantity="item.quantity"
            :is-multiple-offers="false" />
    </div>
</template>

<script>
    import TicketOfferCardVariant1 from '@tenant/app/components/cards/TicketOfferCardVariant1';

    export default {
        name:  'EventTicketDetailSidebarSingleOfferContainer',
        components: {
            TicketOfferCardVariant1
        },
        props: {
            item: {
                type: Object,
                required: true,
            },
            ticket: {
                type: Object,
                required: true,
            }
        }
    };
</script>